import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styles from "./winners.module.scss";
import "../../../styles/corona-island.scss";

const Winners = () => {

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        
        <h1>ANUNCIAMOS LOS GANADORES <br/> EL 5/12/2022</h1>
        <h3>AÚN ESTÁS A TIEMPO DE SER UNOS DE LOS 5 GANADORES.<br/> NO TE QUEDES AFUERA</h3>

        <AniLink
          to={'/islacorona/form'}
          cover
          direction="left"
          duration={0.5}
          bg="#ffc311"
          className={styles.btn}
        >
          PARTICIPAR
        </AniLink>
      </div>
    </section>
  );
};

export default Winners;
